import React, { useEffect, useState } from "react";
import { apiClient } from "../../services/http";
import Papa from "papaparse";

type Harvest = {
    id: string;
    harvest_type: string;
    url: string;
    schedule: string;
    sitemap_filter: string | null;
    paths: string | null;
    exclude_paths: string | null;
    provider: string | "";
    sitemaps: string | null;
    search_terms: string | null;
    qa_mode: boolean;
    bulk_upload_id: string | null;
};

type ApiResponse = {
    continuation_key?: string;
    harvests: Harvest[];
};

type DocumentCountResponse = {
    total: number;
    past_week: { date: string; count: number }[];
};


type UrlsResponse = {
    url: string;
    date_harvested: string;
    harvester: string;
}[];


export default function HarvestApi() {
    const [harvests, setHarvests] = useState<Harvest[]>([]);
    const [newHarvest, setNewHarvest] = useState<Harvest>({
        id: "",
        harvest_type: "",
        url: "",
        schedule: "",
        sitemap_filter: null,
        paths: null,
        exclude_paths: null,
        provider: "",
        sitemaps: null,
        search_terms: null,
        qa_mode: false,
        bulk_upload_id: null,
    });
    const [singleHarvestId, setSingleHarvestId] = useState<string>("");
    const [singleHarvest, setSingleHarvest] = useState<Harvest | null>(null);
    const [updateHarvest, setUpdateHarvest] = useState<Harvest>({
        id: "",
        harvest_type: "",
        url: "",
        schedule: "",
        sitemap_filter: null,
        paths: null,
        exclude_paths: null,
        provider: "",
        sitemaps: null,
        search_terms: null,
        qa_mode: false,
        bulk_upload_id: null,
    });

    const [bulkHarvestData, setBulkHarvestData] = useState({
        urls: "",
        search_terms: "",
        schedule: "",
        harvest_type: "",
        provider: "",
    });

    const [documentCount, setDocumentCount] = useState<DocumentCountResponse | null>(null);
    const [harvestUrls, setHarvestUrls] = useState<UrlsResponse>([]);
    const [csvData, setCsvData] = useState<Record<string, string[]>>({});

    const getAllHarvests = async () => {
        try {
            const response = await apiClient.get<ApiResponse>("/");
            setHarvests(response.data.harvests || []);
        } catch (error) {
            console.error("Error fetching harvests:", error);
        }
    };

    const createHarvest = async () => {
        try {
            const response = await apiClient.post<Harvest>("/", newHarvest);
            setNewHarvest({
                id: "",
                harvest_type: "",
                url: "",
                schedule: "",
                sitemap_filter: null,
                paths: null,
                exclude_paths: null,
                provider: "",
                sitemaps: null,
                search_terms: null,
                qa_mode: false,
                bulk_upload_id: null,
            });
        } catch (error) {
            console.error("Error creating harvest:", error);
        }
    };

    const getSingleHarvest = async () => {
        try {
            const response = await apiClient.get<Harvest>(`/${singleHarvestId}`);
            setSingleHarvest(response.data);
        } catch (error) {
            console.error("Error fetching single harvest:", error);
        }
    };

    const updateExistingHarvest = async () => {
        try {
            const { id, ...updateData } = updateHarvest;
            await apiClient.put(`/${id}`, updateData);
        } catch (error) {
            console.error("Error updating harvest:", error);
        }
    };

    const deleteHarvest = async (id: string) => {
        try {
            await apiClient.delete(`/${id}`);
        } catch (error) {
            console.error("Error deleting harvest:", error);
        }
    };

    const getDocumentCount = async () => {
        try {
            const response = await apiClient.get<DocumentCountResponse>(`/${singleHarvestId}/count`);
            setDocumentCount({
                total: response.data.total,
                past_week: response.data.past_week || [],
            });
        } catch (error) {
            console.error("Error fetching document count:", error);
        }
    };
    

    const getHarvestUrls = async () => {
        try {
            const response = await apiClient.get<UrlsResponse>(`/${singleHarvestId}/urls?from=0&size=100`);
            setHarvestUrls(response.data);
        } catch (error) {
            console.error("Error fetching harvest URLs:", error);
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            alert("Please select a CSV file.");
            return;
        }
    
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
                const data = result.data as Array<Record<string, string>>;
   
                console.log("Parsed Data:", data);
    
                const UrlIndex = "URL";
                const typeIndex = "Type";
    

                const processedData = data
                    .map((row, index) => {
                        var url = row[UrlIndex]?.trim() || "";

                        if (!url.startsWith("http")) {
                            url = `https://${url}`;
                        }
                        var type = row[typeIndex]?.trim() || "";
                        switch (type.toLowerCase())
                        {
                            case "rss":
                                type = "rss-feed";
                                break;
                            case "web":
                                type = "site-harvest";
                                break;
                        }

                        if (!url || !type) {
                            console.warn(`Skipping row ${index + 1}:`, row);
                            return null;
                        }
    
                        return { url, type };
                    })
                    .filter(Boolean) as Array<{ url: string; type: string }>;
    

                console.log("Processed Rows:", processedData);
    
                const groupedData = processedData.reduce((acc: Record<string, string[]>, row) => {
                    if (!acc[row.type]) acc[row.type] = [];
                    acc[row.type].push(row.url);
                    return acc;
                }, {});
    
                console.log("Grouped Data by Type:", groupedData);
    
                setCsvData(groupedData);
                alert("CSV uploaded and grouped by type successfully.");
            },
            error: (error) => {
                console.error("Error parsing CSV:", error);
                alert("Failed to parse CSV file.");
            },
        });
    };
    
    

    const CreateHarvestsFromCSV = async () => {
        if (!csvData || Object.keys(csvData).length === 0) {
            alert("No valid data found in CSV.");
            return;
        }
    
        for (const [type, urls] of Object.entries(csvData)) {
            var schedule = ""
            switch (type.toLowerCase())
            {
                case "rss-feed":
                    schedule = "*/15 * * * *";
                    break;
                case "site-harvest":
                    schedule = "30 4 * * *";
                    break;
            }
            const payload = {
                urls,
                schedule,
                harvest_type: type,
            };
            try {
                await apiClient.post("/bulk", payload);
                console.log(`Bulk harvest request sent for type: ${type}`);
            } catch (error) {
                console.error(`Failed to create bulk harvest for type: ${type}`, error);
            }
        }
    
        alert("Bulk harvest requests have been sent for all types.");
    };


    const createBulkHarvest = async () => {
        try {
            const { urls, search_terms, schedule, harvest_type, provider } = bulkHarvestData;
    
            const payload: any = {
                schedule,
                harvest_type,
                provider,
            };
    
            if (urls.trim()) {
                payload.urls = urls.split(",").map((url) => url.trim());
            }
    
            if (search_terms.trim()) {
                payload.search_terms = search_terms.split(",").map((term) => term.trim());
            }
    
            if (!payload.urls && !payload.search_terms) {
                alert("Please provide at least one URL or search term.");
                return;
            }

            await apiClient.post("/bulk", payload);
    
            setBulkHarvestData({
                urls: "",
                search_terms: "",
                schedule: "",
                harvest_type: "",
                provider: "",
            });
    
            alert("Bulk harvest creation successful.");
        } catch (error) {
            console.error("Error creating bulk harvest:", error);
            alert("Failed to create bulk harvest.");
        }
    };

    return (
        <div className="App">
            <h1>Harvest Management</h1>

            <button onClick={getAllHarvests}>Get All Harvests</button>
            <div>
                {harvests.map((harvest) => (
                    <div key={harvest.id} style={{ border: "1px solid #ddd", padding: "10px", marginBottom: "10px" }}>
                        <p><strong>ID:</strong> {harvest.id}</p>
                        <p><strong>Harvest Type:</strong> {harvest.harvest_type}</p>
                        <p><strong>URL:</strong> {harvest.url}</p>
                        <button onClick={() => deleteHarvest(harvest.id)}>Delete</button>
                    </div>
                ))}
            </div>

            <h2>Create New Harvest</h2>
            <div>
                <input
                    name="url"
                    placeholder="URL"
                    value={newHarvest.url}
                    onChange={(e) =>
                        setNewHarvest({ ...newHarvest, url: e.target.value })
                    }
                />
                <input
                    name="schedule"
                    placeholder="Schedule (cron)"
                    value={newHarvest.schedule}
                    onChange={(e) =>
                        setNewHarvest({ ...newHarvest, schedule: e.target.value })
                    }
                    
                />
                <input
                    name="harvest_type"
                    placeholder="Harvest Type"
                    value={newHarvest.harvest_type}
                    onChange={(e) =>
                        setNewHarvest({ ...newHarvest, harvest_type: e.target.value })
                    }
                />
                <input
                    name="provider"
                    placeholder="Provider"
                    value={newHarvest.provider}
                    onChange={(e) =>
                        setNewHarvest({ ...newHarvest, provider: e.target.value })
                    }
                />
                <button onClick={createHarvest}>Create Harvest</button>
            </div>

            <h2>Get Single Harvest</h2>
            <div>
                <input
                    placeholder="Harvest ID"
                    value={singleHarvestId}
                    onChange={(e) => setSingleHarvestId(e.target.value)}
                />
                <button onClick={getSingleHarvest}>Get Harvest</button>
                {singleHarvest && (
                    <div key={singleHarvest.id} style={{ border: "1px solid #ddd", padding: "10px", marginBottom: "10px" }}>
            <p><strong>ID:</strong> {singleHarvest.id}</p>
            <p><strong>Harvest Type:</strong> {singleHarvest.harvest_type}</p>
            <p><strong>URL:</strong> {singleHarvest.url}</p>
            <p><strong>Schedule:</strong> {singleHarvest.schedule}</p>
            <p><strong>Sitemap Filter:</strong> {singleHarvest.sitemap_filter || 'N/A'}</p>
            <p><strong>Paths:</strong> {singleHarvest.paths || 'N/A'}</p>
            <p><strong>Exclude Paths:</strong> {singleHarvest.exclude_paths || 'N/A'}</p>
            <p><strong>Provider:</strong> {singleHarvest.provider || 'N/A'}</p>
            <p><strong>Sitemaps:</strong> {singleHarvest.sitemaps || 'N/A'}</p>
            <p><strong>Search Terms:</strong> {singleHarvest.search_terms || 'N/A'}</p>
            <p><strong>QA Mode:</strong> {singleHarvest.qa_mode ? 'Enabled' : 'Disabled'}</p>
            <p><strong>Bulk Upload ID:</strong> {singleHarvest.bulk_upload_id || 'N/A'}</p>
            <button onClick={() => deleteHarvest(singleHarvest.id)}>Delete</button>
                    </div>
                )}
            </div>

            <h2>Get Document Count</h2>
            <div>
                <button onClick={getDocumentCount}>Get Document Count</button>
                {documentCount && (
    <div style={{ border: "1px solid #ddd", padding: "10px", marginBottom: "10px" }}>
        <p><strong>Total Documents:</strong> {documentCount.total}</p>
        <h4>Daily Documents:</h4>
        {documentCount.past_week.length > 0 ? (
            documentCount.past_week.map((entry, index) => (
                <p key={index}>
                    <strong>Date:</strong> {entry.date}, <strong>Count:</strong> {entry.count}
                </p>
            ))
        ) : (
            <p>No document activity in the past week.</p>
        )}
        </div>
)}
            </div>

            <h2>Get Harvest URLs</h2>
            <div>
                <button onClick={getHarvestUrls}>Get Harvest URLs</button>
                {harvestUrls.length > 0 ? (
                    <div style={{ border: "1px solid #ddd", padding: "10px", marginBottom: "10px" }}>
                        <h4>Harvested URLs:</h4>
                        {harvestUrls.map((entry, index) => (
                            <p key={index}>
                                <strong>URL:</strong> {entry.url}, <strong>Date Harvested:</strong> {entry.date_harvested},{" "}
                                <strong>Harvester:</strong> {entry.harvester}
                            </p>
                        ))}
                    </div>
                ) : (
                    <p>No URLs found.</p>
                )}
            </div>


            <h2>Bulk Harvest Creation</h2>
            <div>
                <textarea
                    name="urls"
                    placeholder="Enter URLs, separated by commas"
                    value={bulkHarvestData.urls}
                    onChange={(e) =>
                        setBulkHarvestData({ ...bulkHarvestData, urls: e.target.value })
                    }
                    rows={3}
                    style={{ width: "100%" }}
                />
                <textarea
                    name="search_terms"
                    placeholder="Enter search terms, separated by commas"
                    value={bulkHarvestData.search_terms}
                    onChange={(e) =>
                        setBulkHarvestData({ ...bulkHarvestData, search_terms: e.target.value })
                    }
                    rows={3}
                    style={{ width: "100%" }}
                />
                <input
                    name="schedule"
                    placeholder="Schedule (cron)"
                    value={bulkHarvestData.schedule}
                    onChange={(e) =>
                        setBulkHarvestData({ ...bulkHarvestData, schedule: e.target.value })
                    }
                    style={{ width: "100%", marginTop: "10px" }}
                />
                <input
                    name="harvest_type"
                    placeholder="Harvest Type"
                    value={bulkHarvestData.harvest_type}
                    onChange={(e) =>
                        setBulkHarvestData({ ...bulkHarvestData, harvest_type: e.target.value })
                    }
                    style={{ width: "100%", marginTop: "10px" }}
                />
                <input
                    name="provider"
                    placeholder="Provider"
                    value={bulkHarvestData.provider}
                    onChange={(e) =>
                        setBulkHarvestData({ ...bulkHarvestData, provider: e.target.value })
                    }
                    style={{ width: "100%", marginTop: "10px" }}
                />
                <button onClick={createBulkHarvest} style={{ marginTop: "10px" }}>
                    Create Bulk Harvest
                </button>

            <h2>Upload CSV for Bulk Harvest</h2>
            <div>
                <input type="file" accept=".csv" onChange={handleFileUpload} />
                <button onClick={CreateHarvestsFromCSV} style={{ marginTop: "10px" }}>
                    Create Harvests from CSV
                </button>
            </div>
        </div>
        </div>
    );
}
