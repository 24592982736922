import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Switch from "@material-ui/core/Switch";
import ListItemText from "@material-ui/core/ListItemText";
import Loading from "../../Layout/Loading";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ListItemIcon } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { updateOrganisationMFA, getOrganisationMFA } from "../../../services/http-organisation";
import { OrganisationMFA } from "../../../models/Organisation/OrganisationMFA";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        expiresText: {
            marginRight: theme.spacing(1),
        },
        settingsList: {
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            maxWidth: 460,
        },
    })
);

export default function GroupSubscriptions() {
    const classes = useStyles();
    const { organisationId } = useParams<{ organisationId: string }>();

    const [gridLoading, setGridLoading] = useState<boolean>(false);
    const [mfa, setMFA] = useState<OrganisationMFA>({ enabled: false });

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [organisationId]);

    async function fetchData() {
        setGridLoading(true);

        const mfa = await getOrganisationMFA(organisationId);
        setMFA(mfa);

        setGridLoading(false);
    }

    async function toggleMFAForOrganisation(isActivated: boolean) {
        setMFA({ enabled: isActivated });
        await updateOrganisationMFA(organisationId, isActivated);
        fetchData();
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={classes.settingsList}>
                {gridLoading ? (
                    <Loading />
                ) : (
                    <>
                        <List>
                            <ListItem>
                                <ListItemIcon style={{ minWidth: 40 }}>
                                    <Switch
                                        checked={mfa.enabled || false}
                                        onChange={(_, value) => toggleMFAForOrganisation(value)}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={"Multi-factor authentication"} />
                            </ListItem>
                        </List>
                    </>
                )}
            </div>
        </MuiPickersUtilsProvider>
    );
}
