import React from "react";
import { KeyedArray, getKeyedString } from "../models/keyed";
import { Link } from "react-router-dom";
import DataTableEntries from "../models/DataTableEntries";
import Typography from "@material-ui/core/Typography";
import { accountClient } from "./http";
import { OrganisationList } from "../models/Organisation/OrganisationList";
import { Organisation } from "../models/Organisation/Organisation";
import { OrganisationMFA } from "../models/Organisation/OrganisationMFA";

export async function getOrganisations(q?: string): Promise<{ count: number; organisations: Organisation[] }> {
  const params = { q };
  const { data } = await accountClient.get<OrganisationList>("/organisations/", { params });

  return {
    count: data.total_items,
    organisations: data.organisations.sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))
  };
}

export async function getOrganisation(organisationId: string): Promise<Organisation> {
  const { data } = await accountClient.get<Organisation>(`/organisations/${encodeURI(organisationId)}/`);
  
  return data;
}

export async function createOrganisation(name: string, description: string): Promise<{ organisation_id: string }> {
  const { data } = await accountClient.post<{ organisation_id: string }>("/organisations/", { name, description });
  return data;
}

export async function addUsersToOrganisation(organisationId: string, userIds: string[]): Promise<void> {
  await Promise.all(userIds.map(userId => accountClient.put(
    `/organisations/${organisationId}/users/${userId}/`,
  )));
}

export async function getOrganisationMFA(organisationId: string): Promise<OrganisationMFA> {
  const { data } = await accountClient.get<OrganisationMFA>(`/organisations/${organisationId}/mfa/`);
  return data;
}

export async function updateOrganisationMFA(organisationId: string, enabled: boolean): Promise<void> {
  await accountClient.put(`/organisations/${organisationId}/mfa/`, { enabled });
}

export function getKeyedOrganisationsArray(
  organisations: Organisation[],
  searchTerm: string,
  startItem: number,
  endItem: number
): DataTableEntries {
  const filteredOrganisations = organisations
    .filter((x) => !searchTerm || x.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const keyedArr = filteredOrganisations.slice(startItem, endItem).map<KeyedArray>((org) => {
    const groupLink = (
      <Typography color="secondary">
        <Link color="inherit" to={`/manage/organisations/${org.organisation_id}`}>
          {org.name}
        </Link>
      </Typography>
    );

    return {
      key: org.organisation_id,
      entries: [
        getKeyedString(groupLink,"name"),
        getKeyedString(org.description, "description"),
      ],
    };
  });

  return {
    keyedArr: keyedArr,
    count: filteredOrganisations.length,
    headers: ["Name", "Description"],
  };
}
